import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";

import { AppRoutingModule } from "./app.routing";

import { LoginGuardService } from './guards/login-guard.service';
import { AdminGuardService } from './guards/admin-guard.service';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { AppComponent } from "./app.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { DialogModule } from '@ngneat/dialog';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule   , 
    NgxSpinnerModule,
    DialogModule.forRoot(),
    GooglePlaceModule
  ],
  declarations: [AppComponent],
  providers: [LoginGuardService,AdminGuardService],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { CommonService } from "../common.service";

@Injectable({
  providedIn: "root",
})
@Injectable()
export class AdminGuardService implements CanActivate {
  constructor(public cs: CommonService, private router: Router) {}

  canActivate(): boolean {
    let userRole = this.cs.user.role.name;
    if (userRole == "admin") {
      return true;
    } else if (userRole == "sub-admin") {
      // this.router.navigateByUrl("/sub-admin");
      return true;
    } else {
      this.router.navigateByUrl("/admin");
    }
    return false;
  }
}

import { Component} from '@angular/core';
import { Router } from '@angular/router';

import { CommonService } from '../app/common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor( private router: Router, public cs: CommonService) {
  }
  ngOnInit(): void {
   this.cs.getUserProfile();
  }
  

}

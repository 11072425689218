import { Injectable } from '@angular/core';
import { CanActivate,Router } from '@angular/router';

import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})

export class LoginGuardService implements CanActivate {
  constructor(public cs: CommonService, private router : Router) {}

  canActivate(): boolean {
    let userRole = this.cs.user.role.name;
  
      if(!userRole){
        return true;
      }else if(userRole == 'admin'){
        this.router.navigateByUrl("/admin-panel");
      }else if(userRole == 'sub-admin'){
        this.router.navigateByUrl("/sub-admin");
      }
      return false;
  }  
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  RouterEvent,
} from "@angular/router";
import { environment } from "../environments/environment";
import { Subject, BehaviorSubject } from "rxjs";
import { CdkStepperNext } from "@angular/cdk/stepper";
import { NgxSpinnerService } from "ngx-spinner";

declare const $: any;

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public user: any = { role: {} };
  public MAIN_URL = `https://themissedconnection.co`;
  // public MAIN_URL = `http://localhost:4222`;
  public SERVICE_URL = this.MAIN_URL + `/api`;
  public showLoader = false;
  public isAdmin = false;
  googleKey = "AIzaSyA0o7DFkwfmgTYuuUWuIoVzHHkrnewiQNk";
  loggedUser = new BehaviorSubject(undefined);
  selectedNav = new BehaviorSubject("organization");
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: localStorage.getItem("token") || "",
    }),
  };
  constructor(
    public http: HttpClient,
    public router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.router.events.subscribe((RouterEvent: Event) => {
      if (RouterEvent instanceof NavigationStart) {
        this.showLoader = true;
      }

      if (RouterEvent instanceof NavigationEnd) {
        this.showLoader = false;
      }
    });
    if (environment.production) {
      this.MAIN_URL = "";
      this.SERVICE_URL = this.MAIN_URL + `/api`;
    }
    this.refreshHttpOptions();
  }

  request(url, type, data = {}, showLoader = false) {
    if (showLoader) {
      this.spinner.show();
    }

    return new Promise((resolve, reject) => {
      if (type == "post" || type == "put" || type == "patch") {
        this.http[type](
          this.SERVICE_URL + url,
          data,
          this.httpOptions
        ).subscribe(
          (res) => {
            resolve(res);
            if (showLoader) {
              this.spinner.hide();
            }
          },
          (err) => {
            reject(err);
            if (showLoader) {
              this.spinner.hide();
            }
            // console.log('common service',err)
          }
        );
      } else {
        this.http[type](this.SERVICE_URL + url, this.httpOptions).subscribe(
          (res) => {
            resolve(res);
            if (showLoader) {
              this.spinner.hide();
            }
          }
        );
      }
    });
  }

  getDummyArray(length: number) {
    return Array(length);
  }

  setNav(nav?) {
    this.selectedNav.next(nav);
  }

  getNav() {
    return this.selectedNav.asObservable();
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  delete_cookie(name) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  refreshHttpOptions() {
    let options: any = {};
    if (localStorage.getItem("token")) {
      options["Authorization"] = localStorage.getItem("token");
    }

    this.httpOptions = {
      headers: new HttpHeaders(options),
    };
  }

  getHttpOptions() {
    return this.httpOptions;
  }

  setUser(user) {
    this.loggedUser.next(user);
  }

  getUser() {
    return this.loggedUser.asObservable();
  }

  showNotification(type: any, text: string) {
    $.notify(
      {
        icon: "notifications",
      },
      {
        type: type,
        timer: 3000,
        placement: {
          from: "top",
          align: "right",
        },
        template:
          '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
          '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<span data-notify="message">' +
          text +
          "</span>" +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  getCombinedHtml(arr) {
    let finalHtml = "";
    arr.forEach((section, index) => {
      finalHtml +=
        "<h3" +
        ' id="' +
        section.heading.split(" ").join("") +
        '"><b>' +
        (index + 1) +
        ". " +
        section.heading +
        "</b></h3>";
      section.subSections.forEach((subSection, subIndex) => {
        finalHtml +=
          "<h6" +
          ' style="margin-left:1.5rem;text-transform: unset;" id="' +
          subSection.subHeading.split(" ").join("") +
          '"><b>' +
          (index + 1) +
          "." +
          (subIndex + 1) +
          " " +
          subSection.subHeading +
          '</b></h6><div style="margin-left:1.5rem;" class="para">' +
          subSection.content +
          "</div>";
      });
    });
    return finalHtml;
  }

  getUserProfile() {
    return new Promise((resolve) => {
      if (!localStorage.getItem("token")) {
        resolve(null);
      }
      this.request("/user/profile", "get").then((res: any) => {
        if (res.code == 200) {
          this.user = res.data;
          if (this.user.role.name == "Admin") {
            this.isAdmin = true;
          }
          this.setUser(this.user);
          resolve(null);
        } else {
          resolve(null);
        }
      });
    });
  }

  linkReplace(link) {
    if (link) {
      return this.MAIN_URL + "/" + link;
    } else return "";
  }

  logout() {
    localStorage.removeItem("token");
    this.delete_cookie("Authorization");
    location.reload();
  }

  async getFilePath(file?: any) {
    let fd = new FormData();
    for (let key in file) {
      if (file[key]) {
        fd.append(file[key].fieldname, file[key]);
      }
    }

    let fileData: any = [];
    this.showLoader = true;
    await this.request("/common/files", "post", fd).then((res: any) => {
      if (res.code == 200) {
        this.showLoader = false;
        // return res.data.files;
        for (let path of res.data.files) {
          fileData.push({
            path: path.path,
            originalname: path.originalname,
            mimetype: path.mimetype,
            size: path.size,
          });
        }
      } else {
        this.showLoader = false;
      }
    });
    return fileData;
  }

  getLocationData(city) {
    let mapUrl =
      "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=" +
      city +
      "&types=(cities)&components=country:US&key=" +
      this.googleKey;
    return this.http.get(mapUrl);
  }

  getLatAndLongData(address) {
    let mapUrl =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      address +
      "&sensor=false&key=AIzaSyA0o7DFkwfmgTYuuUWuIoVzHHkrnewiQNk";
    return this.http.get(mapUrl);
  }
}

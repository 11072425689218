import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminGuardService } from './guards/admin-guard.service'
import { LoginGuardService } from './guards/login-guard.service'

const routes: Routes = [
  {
    path: "",
    loadChildren: "./landing/landing.module#LandingModule",
    canActivate: [LoginGuardService],
  },
  {
    path: "admin-panel",
    loadChildren: "./admin/admin.module#AdminModule",
    canActivate: [AdminGuardService],
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
